<template>
    <v-dialog v-model="dialog" persistent width="500">
      <common-card>
        <v-card-text class="pa-4 black--text" style="text-align: center;">
          <div class="pt-5 pb-5 top-text" style="width: 100%; height: 100%; justify-content: center;">
              {{ title }}
          </div>
          <div class="pt-5 pb-5">
            <span class="confirmation-text">
              {{ subtitle }}
            </span>
          </div>
          <div>
            <v-row>
              <v-col cols="12">
                <v-btn
                  class="common-botton"
                  dark
                  @click="userChoice(true)"
                  >{{ bottonText }}</v-btn
                >
              </v-col>
              <v-col cols="12">
                <v-btn
                  text
                  color="#00a7e4"
                  outlined
                  dark
                  style="border-radius: 15px;"
                  @click="userChoice(false)"
                >
                  Cancelar</v-btn
                >
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </common-card>
    </v-dialog>
  </template>
  
  <script>
  import CommonCard from "./CommonCard.vue";
  
  export default {
    components: {
      CommonCard,
    },
    props: {
      dialog: Boolean,
      title: String,
      subtitle: String,
      bottonText: String,
    },
  
    data() {
      return {};
    },
    methods: {
      userChoice(val) {
        this.$emit("userChoice", val);
      },
    },
  };
  </script>
  
  <style>
  .v-toolbar--dense .v-toolbar__content,
  .v-toolbar--dense .v-toolbar__extension {
    padding-top: 0;
    padding-bottom: 0;
    justify-content: center;
  }
  
  .top-text{
  width: 369px;
  height: 22px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 110%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #4D5358;
  flex: none;
  order: 0;
  flex-grow: 1;
  }
  
  
  .confirmation-text{
    /* Por favor, asegúrate de revisar cuidadosamente los cambios antes de continuar */
  
  width: 369px;
  height: 30px;
  
  /* Subtitle/S */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 110%;
  /* or 15px */
  text-align: center;
  
  color: #555555;
  
  
  /* Inside auto layout */
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  
  }
  
  </style>